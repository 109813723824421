import React, { useRef, useEffect, useState } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import ThreeModel from './ThreeModel';
import * as THREE from 'three';
import { Html, OrbitControls, Environment, PerspectiveCamera, GizmoHelper, GizmoViewport,Sky } from '@react-three/drei';
import { ButtonGroup,Container, Button, Row,Toast,Alert,ToastContainer, Col, Offcanvas } from 'react-bootstrap';
import { FiMove, FiRotateCcw, FiZoomIn } from 'react-icons/fi';
import SceneDetail from '../components/SceneDetail';
import { GLTFExporter } from 'three/examples/jsm/exporters/GLTFExporter';
import { USDZExporter } from 'three/examples/jsm/exporters/USDZExporter';
import { BiObjectsVerticalCenter } from 'react-icons/bi';
import axios from 'axios';

function CanvasView({ data, handleSceneRender,loadingScene,handleNewChange }) {

  const sceneRef = useRef();
  const canvasRef = useRef();
  const orbitControlsRef = useRef(null);
  const objectDataDetailRef = useRef(data);
  const [objectDataDetail, setObjectDataDetail] = useState(data);
  const [renderScene, setRenderScene] = useState(false);
  const [showOrbitControl, setShowOrbitControl] = useState(true);
  const [sceneLoaded, setSceneLoaded] = useState(false);
  const [selectedObject, setSelectedObject] = useState(null);
  const [action, setAction] = useState('translate');
  const [showSceneUpdate, setShowSceneUpdate] = useState(false);
  const [activeObject, setActiveObject] = useState(1);
  const [camera, setCamera] = useState(null);
  const [canvasInitialized, setCanvasInitialized] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertVarient, setAlertVarient] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [showUpdateLoad, setshowUpdateLoad] = useState(false);

  const apiUrl = 'https://api.infinityvoid.io/aiApi/imageSearch/search/?q=';


  useEffect(() => {
    if (orbitControlsRef.current) {
      orbitControlsRef.current.enabled = showOrbitControl;
    }
  }, [orbitControlsRef.current, showOrbitControl,showSceneUpdate]);


  useEffect(() => {
    if (camera && !camera.isPerspectiveCamera) {
      console.error('Camera is not a PerspectiveCamera.');
    }
  }, [camera]);

  useEffect(() => {
    if (data.length > 0) {
      objectDataDetailRef.current = data;
      setShowMessage(true);
      setAlertVarient('success');
      setAlertMessage('Please Wait ')
      setObjectDataDetail(data);
      setRenderScene(true);
      setSceneLoaded(true);
      setshowUpdateLoad(false)
      handleSceneRender(true);

    }
  }, [data, handleSceneRender]);

 useEffect(() => {
  }, [renderScene]);

  const handleObjectSelectionCustom = (item) => {
    setActiveObject(item.id);
    setSelectedObject(item);
    setShowSceneUpdate(!showSceneUpdate)
  };

  const handleObjectSelection = (item) => {
    setActiveObject(item.id);
    setSelectedObject(item);
   // setShowOrbitControl(!showOrbitControl);
    setShowSceneUpdate(!showSceneUpdate)
  };

  const handleObjectTransform = (data) => {

    const transformedItemIndex = objectDataDetailRef.current.findIndex(
      (e) => e.modelUrl === data.modelUrl && e.id === data.uniqueId
    );

    const item = objectDataDetailRef.current[transformedItemIndex];

    if (transformedItemIndex !== -1) {
      const updatedObjectArray = [...objectDataDetailRef.current];

      if (action === 'translate') {
        updatedObjectArray[transformedItemIndex].position.X = data.position.x;
        updatedObjectArray[transformedItemIndex].position.Y = data.position.z;
        updatedObjectArray[transformedItemIndex].position.Z = data.position.y;
      }

      if (action === 'rotate') {
        updatedObjectArray[transformedItemIndex].rotation.X = data.rotation.x;
        updatedObjectArray[transformedItemIndex].rotation.Y = data.rotation.z;
        updatedObjectArray[transformedItemIndex].rotation.Z = data.rotation.y;
      }


      if (action === 'scale') {
        updatedObjectArray[transformedItemIndex].scale.X = data.scale.x;
        updatedObjectArray[transformedItemIndex].scale.Y = data.scale.z;
        updatedObjectArray[transformedItemIndex].scale.Z = data.scale.y;
      }

      objectDataDetailRef.current = updatedObjectArray;
      setActiveObject(data.uniqueId);

    } else {
      console.error('Item not found in objectArray');
    }
  };

  const handleObjectAdded = (objectDetails) => {
    const transformedItemIndex = data.findIndex((e) => e.modelUrl === objectDetails);
    if (transformedItemIndex === data.length - 1) {
      setSceneLoaded(true);
    }
  };

  useEffect(() => {
    if (sceneRef.current) {
      // Access scene children once all objects have been added

    }
  }, [sceneLoaded]);

  const handleActionChange = (actionType) => {
    setAction(actionType);
  };

  const clearSelection = () => {
    setSelectedObject(null);
  };

  const handleClick = (event) => {
    const canvas = canvasRef.current;
  
    if (!canvas || !canvasInitialized || !camera || !camera.isPerspectiveCamera) {
      console.error('Canvas or camera is not properly initialized.');
      return;
    }
  
    const { offsetX, offsetY } = event;
    const rect = canvas.getBoundingClientRect();
    const x = (offsetX / rect.width) * 2 - 1;
    const y = -(offsetY / rect.height) * 2 + 1;
  
    const raycaster = new THREE.Raycaster();

    
    // Ensure camera is not undefined before using it
    if (!camera.isPerspectiveCamera) {
      console.error('Camera is not a PerspectiveCamera.');
      return;
    }
  
    raycaster.setFromCamera({ x, y }, camera);
  
    const intersects = raycaster.intersectObjects(sceneRef.current.children, true);
  
  
    if (intersects.length === 0) {
      // Handle click event when no objects are intersected
    } else {
      // Handle click event when objects are intersected
    }
  };



  const handleExport = () => {
    const exporter = new USDZExporter();
    const sceneClone = sceneRef.current.clone(true);
    

   
    const objectsToKeep = sceneClone.children.filter(group => {
      // Exclude TransformControls and GridHelper
      return !(group.isTransformControls || group instanceof THREE.GridHelper);
    });
    
    // Clear the scene
    sceneClone.children.length = 0;
    
    // Add the objects we want to keep back to the scene
    objectsToKeep.forEach(object => {
      sceneClone.add(object);
    });

   


/*
    exporter.parse(
      sceneClone,
      (result) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(new Blob([JSON.stringify(result)], { type: 'application/octet-stream' }));
        link.download = 'scene.glb';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },

      {}
    );
   */

    exporter.parse(sceneClone, (result) => {
      const blob = new Blob([result], { type: 'application/octet-stream' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'scene.usdz';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });


  };



  const handleUpdatePosition = (objectId, newPosition) => {
   

    const transformedItemIndex = objectDataDetailRef.current.findIndex(
     (e) => e.id === objectId
   );
   if (transformedItemIndex !== -1) {
     const updatedObjectArray = [...objectDataDetailRef.current];
     const item = objectDataDetailRef.current[transformedItemIndex];
     
       updatedObjectArray[transformedItemIndex].position.X = newPosition[0];
       updatedObjectArray[transformedItemIndex].position.Y = newPosition[1];
       updatedObjectArray[transformedItemIndex].position.Z = newPosition[2];
    
 
     objectDataDetailRef.current = updatedObjectArray;
     handleObjectSelectionCustom(item);
     
   } else {
     console.error('Item not found in objectArray');
   }
 
   };
 
   const handleUpdateRotation = (objectId, newRotation) => {
     
 
     const transformedItemIndex = objectDataDetailRef.current.findIndex(
       (e) => e.id === objectId
     );
     if (transformedItemIndex !== -1) {
       const updatedObjectArray = [...objectDataDetailRef.current];
   
       const item = objectDataDetailRef.current[transformedItemIndex];
 
         updatedObjectArray[transformedItemIndex].rotation.X = newRotation[0];
         updatedObjectArray[transformedItemIndex].rotation.Y = newRotation[1];
         updatedObjectArray[transformedItemIndex].rotation.Z = newRotation[2];
      
   
       objectDataDetailRef.current = updatedObjectArray;
       handleObjectSelectionCustom(item);
 
     } else {
       console.error('Item not found in objectArray');
     }
 
   };
 
 
   const handleUpdateScale = (objectId, newScale) => {
 
     const transformedItemIndex = objectDataDetailRef.current.findIndex(
       (e) => e.id === objectId
     );

     
     if (transformedItemIndex !== -1) {
       const updatedObjectArray = [...objectDataDetailRef.current];
       const item = objectDataDetailRef.current[transformedItemIndex];
 
       
         updatedObjectArray[transformedItemIndex].scale.X = newScale[0];
         updatedObjectArray[transformedItemIndex].scale.Y = newScale[1];
         updatedObjectArray[transformedItemIndex].scale.Z = newScale[2];
      
   
       objectDataDetailRef.current = updatedObjectArray;
       handleObjectSelectionCustom(item);
 
     } else {
       console.error('Item not found in objectArray');
     }
 
   };

   const handleUpdateScene = (data) => {
    setshowUpdateLoad(true);
        // Hide the render and show the message
    setRenderScene(false);
    setShowMessage(true);
    setAlertVarient('Warning');
    setAlertMessage('Please wait ');

    objectDataDetailRef.current = [];
setObjectDataDetail([]);
  
    // Clear all objects from the scene
    const scene = sceneRef.current;
  
    while (scene.children.length > 0) {
      const child = scene.children[0];
      scene.remove(child);
  
      // If the child is a mesh or other object with its own children, remove them as well
      
    }
  
    // Proceed with handling new data
    handleNewChange(data);
  };

   const handleDeleteObject = (object)=>{

    const itemIndex = objectDataDetailRef.current.findIndex(
      (e) => e.id === object.id
    );
    if (itemIndex !== -1) {
      const updatedObjectArray = [...objectDataDetailRef.current];

      console.log(sceneRef);

      sceneRef.current.traverse(function(child){
       if(child.name == object.id){
        sceneRef.current.remove(child);
       }
    });

      //array.splice(index, 1); 
      updatedObjectArray.splice(itemIndex,1);
        
      setShowMessage(true);
      setAlertVarient('success');
      setAlertMessage('Object Deleted  ');
     
  
      objectDataDetailRef.current = updatedObjectArray;
      setActiveObject(null);
    setSelectedObject(null);
    setShowSceneUpdate(!showSceneUpdate)

    } else {

      setShowMessage(true);
      setAlertVarient('error');
      setAlertMessage('Item not found in objectArray ')

      console.error('Item not found in objectArray');
    }
  
   }


   const handleRefetchObject = async(object)=>{

    //setRenderScene(false);
    setShowMessage(true);
    setAlertVarient('Warning');
    setAlertMessage('Please wait ');
 
    const itemIndex = objectDataDetailRef.current.findIndex(
      (e) => e.id === object.id
    );
    if (itemIndex !== -1) {
      const updatedObjectArray = [...objectDataDetailRef.current];

      sceneRef.current.traverse(function(child){
       if(child.name == object.id){
        sceneRef.current.remove(child);
       }
    });


    const response = await axios.get(apiUrl + object.object_name);
  


    if (response.status === 200) {

      setShowMessage(true);
      setAlertVarient('success');
      setAlertMessage('Please wait while the new object is loading');

      const data = response.data.filter(e => e.modelUrl !== object.modelUrl);
      if (data.length > 0) {
          // Generate a random index
          const randomIndex = Math.floor(Math.random() * data.length);
          // Select a random modelUrl
          const modelUrl = data[randomIndex].modelUrl;
          
          updatedObjectArray[itemIndex].modelUrl = modelUrl;
         

      }
  }
     const item = objectDataDetailRef.current[itemIndex];

      objectDataDetailRef.current = updatedObjectArray;
      handleObjectSelectionCustom(item);
      setRenderScene(true);
    } else {

      
      setShowMessage(true);
      setAlertVarient('Danger');
      setAlertMessage('Object Refetch failed please try again ')

      console.error('Item not found in objectArray');
    }

   }

  return (
    <>

<ToastContainer position={'bottom-end'}>
<Toast  bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>

{showSceneUpdate && renderScene == false &&
(
  <Container className='container-border-animation' style={{ height: '84vh', width: '100%', background: '#393E46', textAlign: 'center',borderRadius:"12px",border:"2px solid #EEEEEE" }}>
            
  <div className="spinner-border" role="status" style={{marginTop:"30%"}}>
  <span className="visually-hidden">Loading...</span>
</div>
  <h4 style={{  color:"#EEEEEE",position:"relative"}}>Success ! 3D Environment Created Loading</h4>
</Container>
)}

      {renderScene ? (
        <Row style={{ height: '84vh' }}>

          
<Col xs={8} style={{marginLeft:"2.5%",background:"#393E46",borderRadius:"12px"}}>

{/*
            <div className="btn-group-vertical" style={{ position: 'fixed', top: '10vh', zIndex: 100,padding:"1%" }}>
              <ButtonGroup vertical>
                <Button
                  variant="primary"
                  onClick={() => handleActionChange('translate')}
                  active={action === 'translate'}
                  style={{ backgroundColor: action === 'translate' ? '#007bff' : 'inherit' }}
                >
                  <FiMove />
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleActionChange('rotate')}
                  active={action === 'rotate'}
                  style={{ backgroundColor: action === 'rotate' ? '#007bff' : 'inherit' }}
                >
                  <FiRotateCcw />
                </Button>
                <Button
                  variant="primary"
                  onClick={() => handleActionChange('scale')}
                  active={action === 'scale'}
                  style={{ backgroundColor: action === 'scale' ? '#007bff' : 'inherit' }}
                >
                  <FiZoomIn />
                </Button>
              </ButtonGroup>
            </div>

      */}

            <div className="btn-group-vertical" style={{ position: 'fixed', top: '12vh', left: '60.2%', zIndex: 100 }}>
              <Button variant="primary" onClick={handleExport}>
                Export Scene
              </Button>
            </div>

            <Canvas ref={canvasRef} style={{ height: '84vh' }}  onCreated={() => setCanvasInitialized(true)} onClick={handleClick}>
            <Environment preset="sunset"  />
  <ambientLight intensity={0.5} />
      <directionalLight intensity={0.5} position={[0, 10, 0]} />
      <pointLight intensity={0.5} position={[0, 10, 0]} />
      <spotLight intensity={0.5} position={[0, 10, 0]} angle={Math.PI / 5} penumbra={0.2} />


      <PerspectiveCamera
  ref={setCamera}
  makeDefault
  position={[0, 150, 150]}  // Adjust the Y position to be above the scene
  rotation={[-Math.PI / 2, 0, 0]}  // Rotate the camera to look down along the Z-axis
  fov={75}
  near={10.5}
  far={10000}
/>
  <group ref={sceneRef}>
    {objectDataDetailRef && objectDataDetailRef.current.map((item, index) => {
      const key = item.object_name + index;
      const x = item.position.X;
      const y = item.position.Z;
      const z = item.position.Y;

      const scalex = item.scale.X;
      const scaley = item.scale.Z;
      const scalez = item.scale.Y;


      const rotationX = THREE.MathUtils.degToRad(item.rotation.X);
      const rotationY = THREE.MathUtils.degToRad(item.rotation.Z);
      const rotationZ = THREE.MathUtils.degToRad(item.rotation.Y);

      return (
        <ThreeModel
          key={key}
          showBoundingBox={activeObject === item.id} 
          itemId={item.id}
          modelUrl={item.modelUrl}
          position={[x, y, z]}
          rotation={[rotationX, rotationY, rotationZ]}
          scale={[scalex,scaley,scalez]}
          action={action}
          onSelected={()=>handleObjectSelection(item)}
          onObjectTransform={(data) => handleObjectTransform(data)}
          onObjectAdded={(objectDetails) => handleObjectAdded(objectDetails)}
          setShowOrbitControl={setShowOrbitControl}
        />
      );
    })}

    <gridHelper args={[4000, 1000, 0xff0000, 'grey']} />
  </group>

  <OrbitControls 
  makeDefault ref={orbitControlsRef}  
  enabled={showOrbitControl} 
  enableRotate={true}
  enableZoom={true}
  enablePan={true}
  minPolarAngle={0}   // Adjust if you want to restrict vertical rotation
 // maxPolarAngle={Math.PI}  // Adjust if you want to restrict vertical rotation
 // minAzimuthAngle={-Math.PI / 2}  // Adjust if you want to restrict horizontal rotation
  maxAzimuthAngle={Math.PI / 2} 
   />

  <GizmoHelper alignment="bottom-right">
    <GizmoViewport axisColors={['red', 'green', 'blue']} labelColor="black"  orbitControls={orbitControlsRef.current}/>
  </GizmoHelper>
</Canvas>

          </Col>

          
          <Col xs={3} style={{marginLeft:"3%"}}>
            <SceneDetail
            onDeleteObject={handleDeleteObject}
            onReFetchObject={handleRefetchObject} 
            data={objectDataDetailRef.current} 
            activeObject={activeObject} 
            onSelected={handleObjectSelectionCustom}
            onUpdatePosition={handleUpdatePosition}
           onUpdateRotation={handleUpdateRotation}
           onUpdateScale={handleUpdateScale}
           onUpdateScene={handleUpdateScene}/>
          </Col>
          <Col xs ={1}></Col>
        </Row>
      ) : (
        <>
        {loadingScene == false?(
          <Container  style={{ height: '84vh', width: '100%', background: '#393E46', textAlign: 'center',borderRadius:"12px",border:"2px solid #EEEEEE" }}>
            <Row style={{paddingTop:"30%"}}>
            <h4 style={{  color:"#EEEEEE",position:"relative"}}>Click on Generate Space button to view your scene here.</h4>
            </Row>
          </Container>
          ):(<>
          
  
          <Container className='container-border-animation' style={{ height: '84vh', width: '100%', background: '#393E46', textAlign: 'center',borderRadius:"12px",border:"2px solid #EEEEEE" }}>
            
            <div className="spinner-border" role="status" style={{marginTop:"30%"}}>
            <span className="visually-hidden">Loading...</span>
        </div>
            <h4 style={{  color:"#EEEEEE",position:"relative"}}>Success ! 3D Environment Created Loading</h4>
          </Container>
  
          </>)}
          </>
      )}
    </>
  );
}

export default CanvasView;
