import React, { useState,useRef } from 'react';
import { Container, Row, Col,Toast,Alert,ToastContainer, } from 'react-bootstrap';
import CanvasView from '../components/CanvasView';
import ChatAi from '../components/ChatAi';
import Header from '../components/Header';
import Footer from '../components/Footer';
/*
{   "id":1,
        "object_name": "Classic Wooden Table",
        "position": {
            "X": 0,
            "Y": -100,
            "Z": 0
        },
        "rotation": {
            "X": 0,
            "Y": 0,
            "Z": 0
        },
        "scale": {
          "X": 1,
          "Y": 1,
          "Z": 1
      },
        "modelUrl": "https://genesis-3d-models.s3.ap-south-1.amazonaws.com/glb/TMBackyardPack1/SM_BackyardTable01.glb"
    },
    {   "id":2,
        "object_name": "Comfortable Armchair",
        "position": {
            "X": 0,
            "Y": -220,
            "Z": 0
        },
        "rotation": {
            "X": 0,
            "Y": 0,
            "Z": 180
        },
        "scale": {
          "X": 1,
          "Y": 1,
          "Z": 1
      },
        "modelUrl": "https://genesis-3d-models.s3.ap-south-1.amazonaws.com/glb/TMBackyardPack1/SM_BackyardChair21.glb"
    },
    {   "id":3,
      "object_name": "Relaxing Armchair",
      "position": {
        "X": 0,
        "Y": 20,
        "Z": 0
    },
    "rotation": {
        "X": 0,
        "Y": 0,
        "Z": 225
    },
    "modelUrl": "https://genesis-3d-models.s3.ap-south-1.amazonaws.com/glb/creator-tool/Seating_B_3.glb",
      "scale": {
        "X": 1,
        "Y": 1,
        "Z": 1
    }
  },
*/


function ThreeEditor({user}) {
    const [items, setItems] = useState([ ]); 
    const [sceneLive, setSceneLive] = useState(false); 
    const [loadingScene, setLoadingScene] = useState(false); 

    const [alertMessage, setAlertMessage] = useState();
    const [alertVarient, setAlertVarient] = useState();
    const [showMessage, setShowMessage] = useState(false);
    console.log(sceneLive);

    // Function to update newArray state
    const updateItems = (data) => {
      console.log(data);
        setItems(data);
    };

    const handleSceneRender = (data) =>{
      console.log(data);
  if(data == true){
    setSceneLive(true);
  }
    }

    const loadingInit = (data) =>{
      console.log(data);
  if(data == true){
    setLoadingScene(true);
  }
    }

    const handleNewChange =(data)=>{
      console.log(data);

      if(data.length > 0 ){
        setLoadingScene(true);
        setItems(data);
        handleSceneRender(true)
        setShowMessage(true);
        setAlertVarient('success');
        setAlertMessage('New variant generated successfully.')
      }else{
        setShowMessage(true);
        setAlertVarient('error');
        setAlertMessage('please try after some time.')
      }

    }


    const handleRefresh =()=>{
      console.log("NEW")
      setItems([]);
      setLoadingScene(true);
      setSceneLive(false);
      window.location.reload(true);

 }

  return (
    <Container fluid>

      
<ToastContainer position={'bottom-end'}>
<Toast  bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>


<Row style={{ height: '10vh' }}>
<Header user={user} handleRefresh={handleRefresh}/>
</Row>

      {sceneLive == false?(
        <>
      <Row style={{ height: '84vh' }}>


        <Col xs ={8} style={{borderRadius:"12px",marginLeft:"2.5%"}}>
          <CanvasView data={items} handleSceneRender={handleSceneRender} loadingScene={loadingScene}/>
        </Col>

        <Col xs ={3} style={{marginLeft:"3%"}}>
          <ChatAi updateNewArray={updateItems} loadingInit={loadingInit} />
        </Col>

        <Col xs ={1}>

        </Col>

      </Row>
</>):(
  <>
    <CanvasView data={items} handleSceneRender={handleSceneRender} handleNewChange={handleNewChange}/>
  </>
)}
   
     

    </Container>
  );
}

export default ThreeEditor;