import React, { useState, useRef, useMemo, useEffect } from 'react';
import { TransformControls, useGLTF,Box, PivotControls  } from '@react-three/drei';
import * as THREE from 'three';

function ThreeModel({ setShowOrbitControl, itemId, modelUrl, scale, action,showBoundingBox, position, rotation, onSelected, onObjectTransform, onObjectAdded }) {
  const [selected, setSelected] = useState(false);

  const transformControlsRef = useRef(null);
  const { scene } = useGLTF(modelUrl);

  const uniqueKey = `${modelUrl}-${position.join(',')}-${rotation.join(',')}`;

  const adjustedModel = useMemo(() => {
    if (!scene) return null;

    const group = new THREE.Group();
    scene.traverse((child) => {
      if (child.isMesh) {
        group.add(child.clone());
      }
    });

    const boundingBox = new THREE.Box3().setFromObject(group);
    const boundingBoxCenter = boundingBox.getCenter(new THREE.Vector3());

    group.add(new THREE.Object3D()); // Add a dummy object at the center
    group.name = itemId;
    const container = new THREE.Group();
    container.add(group);
    container.position.copy(boundingBoxCenter);

    container.position.add(new THREE.Vector3(position[0], position[1], position[2]));
    container.rotation.set(rotation[0], rotation[1], rotation[2]);
    container.scale.set(scale[0], scale[1], scale[2]);

    return container;
  }, [scene, uniqueKey, position, rotation, scale]);

  useEffect(() => {
    if (adjustedModel) {
      onObjectAdded(modelUrl);
    }
  }, [adjustedModel, onObjectAdded, modelUrl]);

  const onClick = (e) => {
    onSelected();
    setSelected(!selected);
  };

  const handleObjectTransform = (sceneObject) => {
    const position = sceneObject.position;
    const rotation = sceneObject.rotation;
    const scale = sceneObject.scale;
    const uniqueId = itemId;
    const data = {
      uniqueId,
      modelUrl,
      position,
      rotation,
      scale,
    };
    // onObjectTransform(data);
  };

  if (!adjustedModel) {
    return null; // Return null or a loading spinner while the model is loading
  }


  const handleControl = (e)=>{}
  return (
    <>
    {/*
    <TransformControls
    onChange={(e)=>handleControl(e)}
    space='local'
      onObjectChange={(e) => handleObjectTransform(e.target.object)}
      ref={transformControlsRef}
      mode={action}
      enabled={selected}
      showX={selected}
      showZ={selected}
      showY={selected}
      onDragStart={() => setShowOrbitControl(false)}
      onDragEnd={() => setShowOrbitControl(true)}
      onMouseUp={() => onClick()}
    />
    */}
<PivotControls>
      <primitive object={adjustedModel} onClick={onClick} />
      {showBoundingBox && (
        <mesh>
          <boxHelper args={[adjustedModel]} />
        </mesh>
      )}
      </PivotControls>
      </>
  );
}

export default ThreeModel;
