import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ThreeEditor from './pages/ThreeEditor';
import Auth from './pages/Auth';
import { GoogleOAuthProvider } from '@react-oauth/google';
const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function App() {

  const [isAuth, setIsAuth] = useState(false);
  const [user,setUser] = useState();


  useEffect(()=>{
    if ( sessionStorage.getItem('userId')!==undefined){
  console.log(user);
    }
  },[])
const handleUser =(data)=>{
  setUser(data);
  setIsAuth(true);
}
  return (
    
    <Router>
                  <GoogleOAuthProvider clientId={clientId}>

         <Routes>
          {isAuth?(
<>
<Route exact path="/" element={<ThreeEditor user={user}/>}/>

</>            
          ):(
            <>
                     <Route exact path="/" element={<Auth handleUser={handleUser}/>}/>

            </>
          )}
         
         </Routes>
         </GoogleOAuthProvider>
           </Router>
  );
}

export default App;
