import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Card, ListGroup, InputGroup, Form, Stack,Toast,Alert,ToastContainer, } from 'react-bootstrap';
import { IoMdSend } from "react-icons/io";
import axiosInstance from '../axiosInstance';

function SceneDetail({ data, activeObject, onDeleteObject,onSelected, onReFetchObject, onUpdatePosition, onUpdateRotation, onUpdateScale, onUpdateScene }) {
  const [selectedObject, setSelectedObject] = useState();
  const [sortedData, setSortedData] = useState([]);

  const [position, setPosition] = useState([data[0] && data[0].position.X, data[0] && data[0].position.Y, data[0] && data[0].position.Z]);
  const [rotation, setRotation] = useState([data[0] && data[0].rotation.X, data[0] && data[0].rotation.Y, data[0] && data[0].rotation.Z]);
  const [scale, setScale] = useState([data[0] && data[0].scale.X, data[0] && data[0].scale.Y, data[0] && data[0].scale.Z]);

  const [message, setMessage] = useState();
  
  const [alertMessage, setAlertMessage] = useState();
  const [alertVarient, setAlertVarient] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const listGroupRef = useRef(null);

  const handleSend = async () => {
    let userId = sessionStorage.getItem("userId");

    try {
      const response = await axiosInstance.post('/chat/message', { message: message, userId: userId });
      console.log(response.data);
      onUpdateScene(response.data.message);
      setMessage('');
    } catch (error) {
      console.error(error);
    }
  }

  const handleChangeVarient = async () => {

    setShowMessage(true);
    setAlertVarient('Warning');
    setAlertMessage('Please wait while the new variant is being generated; it takes time');

    let userId = sessionStorage.getItem("userId");
    let changeMessage = "Please redesign it, I don't like objects and their placement."
    try {
      const response = await axiosInstance.post('/chat/message', { message: changeMessage, userId: userId });
      console.log(response.data.message.position);
      onUpdateScene(response.data.message);
      setMessage('');
    } catch (error) {
      console.error(error);

      setShowMessage(true);
      setAlertVarient('error');
      setAlertMessage('Server error');
    }
  }

  const handlePositionChange = (axis, value) => {
    const newPosition = [...position];
    newPosition[axis] = parseFloat(value);
    setPosition(newPosition);
    onUpdatePosition(selectedObject.id, newPosition);
  };

  const handleRotationChange = (axis, value) => {
    const newRotation = [...rotation];
    newRotation[axis] = parseFloat(value);
    setRotation(newRotation);
    onUpdateRotation(selectedObject.id, newRotation);
  };

  const handleDeleteObject = (item) => {
    onDeleteObject(item)
    setSelectedObject();
  };

  const handleRefetchObject = (item) => {
    onReFetchObject(item);
    setSelectedObject();

  };

  const handleScaleChange = (axis, value) => {
    const newScale = [...scale];
    newScale[axis] = parseFloat(value);
    console.log(newScale);
    setScale(newScale);
    onUpdateScale(selectedObject.id, newScale);
  };

  useEffect(() => {
    if (activeObject) {
      const selected = data.find((item) => item.id === activeObject);
      setSelectedObject(selected);
      setPosition([selected && selected.position.X, selected && selected.position.Y, selected && selected.position.Z]);
      setRotation([selected && selected.rotation.X, selected && selected.rotation.Y, selected && selected.rotation.Z]);
      setScale([selected &&  selected.scale.X, selected && selected.scale.Y, selected && selected.scale.Z]);

      // Move the selected object to the first position
      const newSortedData = [selected, ...data.filter(item => item.id !== activeObject)];
      setSortedData(newSortedData);
      listGroupRef.current.scrollTop = 0;  // Scroll to the top
    } else {
      setSortedData(data);
    }
  }, [activeObject, data]);

  const handleObjectClick = (object) => {

    onSelected(object);
    setSelectedObject(object);
    setPosition([object.position.X, object.position.Y, object.position.Z]);
    setRotation([object.rotation.X, object.rotation.Y, object.rotation.Z]);
    setScale([object.scale.X, object.scale.Y, object.scale.Z]);

    // Move the clicked object to the first position
    const newSortedData = [object, ...data.filter(item => item.id !== object.id)];
    setSortedData(newSortedData);
    listGroupRef.current.scrollTop = 0;  // Scroll to the top
  };

  return (
    <>

          
<ToastContainer position={'bottom-end'}>
<Toast  bg={alertVarient} onClose={() => setShowMessage(false)} show={showMessage} delay={3000} autohide>
          <Toast.Header>
           
    
          </Toast.Header>
          <Toast.Body>{alertMessage}</Toast.Body>
        </Toast>
</ToastContainer>
      <Row>
        <Card style={{ background: "#393E46", border: "0", borderColor: "#393E46", height: "200px", overflowY: "hidden" }}>
          <Card.Body>
            <Card.Title style={{ color: "#F7F7F7" }}>Scene Outline</Card.Title>
            <div style={{ maxHeight: "150px", overflowY: "auto" }} ref={listGroupRef}>
              <ListGroup style={{ border: "0" }}>
                {sortedData && sortedData.map((item, index) => (
                  <ListGroup.Item
                    key={index}
                    action
                    onClick={() => handleObjectClick(item)}
                    style={{
                      border: "0px",
                      backgroundColor: selectedObject && selectedObject.id === item.id ? '#007bff' : 'inherit',
                      color: selectedObject && selectedObject.id === item.id ? 'white' : '#F7F7F7'
                    }}
                  >
                    <Stack direction={'horizontal'} gap={3}>
                      {item && item.object_name}

                      {selectedObject && selectedObject.id === item.id && (
                        <>
                          <Button size={'sm'} onClick={(e) => handleDeleteObject(item)}>Delete Object</Button>
                          <Button size={'sm'} onClick={(e) => handleRefetchObject(item)}>Refetch Object</Button>
                        </>
                      )}
                    </Stack>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          </Card.Body>
        </Card>
      </Row>
      <br />
      <Row>
        <Card style={{ background: "#393E46", border: "0", borderColor: "#393E46" }}>
          <Card.Body >
            <Card.Title style={{ color: "#F7F7F7" }}>Details</Card.Title>
            {selectedObject && (
              <div>
                <Form>
                  <Form.Group as={Row} className="mb-3" style={{ color: "#F7F7F7" }}>
                    <Form.Label column sm={3}>
                      Position:
                    </Form.Label>
                    <Col sm={3}>
                      <Form.Control type="number" value={position[0]} onChange={(e) => handlePositionChange(0, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                    <Col sm={3}>
                      <Form.Control type="number" value={position[1]} onChange={(e) => handlePositionChange(1, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                    <Col sm={3}>
                      <Form.Control type="number" value={position[2]} onChange={(e) => handlePositionChange(2, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" style={{ color: "#F7F7F7" }}>
                    <Form.Label column sm={3}>
                      Rotation:
                    </Form.Label>
                    <Col sm={3}>
                      <Form.Control type="number" value={rotation[0]} onChange={(e) => handleRotationChange(0, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                    <Col sm={3}>
                      <Form.Control type="number" value={rotation[1]} onChange={(e) => handleRotationChange(1, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                    <Col sm={3}>
                      <Form.Control type="number" value={rotation[2]} onChange={(e) => handleRotationChange(2, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3" style={{ color: "#F7F7F7" }}>
                    <Form.Label column sm={3}>
                      Scale:
                    </Form.Label>
                    <Col sm={3}>
                      <Form.Control type="number" value={scale[0]} onChange={(e) => handleScaleChange(0, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                    <Col sm={3}>
                      <Form.Control type="number" value={scale[1]} onChange={(e) => handleScaleChange(1, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                    <Col sm={3}>
                      <Form.Control type="number" value={scale[2]} onChange={(e) => handleScaleChange(2, e.target.value)} style={{ background: "transparent", color: "#F7F7F7" }} />
                    </Col>
                  </Form.Group>
                </Form>
              </div>
            )}
          </Card.Body>
        </Card>
      </Row>
      <br />
      <Row>
        {/*
        <Col md={{ span: 10, offset: 1 }}>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Send a message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              style={{ background: "transparent", color: "#F7F7F7" }}
            />
            <Button variant="primary" onClick={handleSend}>
              <IoMdSend />
            </Button>
          </InputGroup>
        </Col>

        */}
        <Col md={{  }}>
          <Button variant="danger" onClick={handleChangeVarient}>Change Variant</Button>
        </Col>
      </Row>
    </>
  );
}

export default SceneDetail;
