import React, { useState } from 'react';
import { Container, Form, Button, InputGroup, Stack } from 'react-bootstrap';
import axiosInstance from '../axiosInstance';
import './ChatUI.css';

const ChatAi = ({ updateNewArray, loadingInit }) => {
  const [areaName, setAreaName] = useState('');
  const [length, setLength] = useState('');
  const [breadth, setBreadth] = useState('');
  const [areaDescription, setAreaDescription] = useState('');
  const [detail, setDetail] = useState('low');

  console.log(sessionStorage.getItem("userId"));
  console.log(detail);

  const handleSend = async () => {
    let totalItems;
    if (detail === 'low') {
      totalItems = "5 to 10 items.";
    } else if (detail === 'medium') {
      totalItems = "10 to 15 items.";
    } else if (detail === 'high') {
      totalItems = "15 to 20 items.";
    }

    let areaSize = `Length ${length}m and Breadth ${breadth}m`;
    let userMessage = `Area Name - ${areaName} Area Size - ${areaSize} Description - ${areaDescription} It should have - ${totalItems}`;
    loadingInit(true);

    try {
      let userId = sessionStorage.getItem("userId");
      console.log(userId);

      const response = await axiosInstance.post('/chat/message', { message: userMessage, userId: userId });
      console.log(response.data);
      updateNewArray(response.data.message);
      setAreaName('');
      setAreaDescription('');
      setLength('');
      setBreadth('');
      setDetail('');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDetailChange = (item) => {
    setDetail(item);
  };

  return (
    <Container className="chat-container">
      <Form style={{ width: "100%" }}>
        <Form.Group className="mb-2">
          <Form.Label>Area Name</Form.Label>
          <Form.Control
            style={{ background: "#393E46", color: "#F7F7F7", borderColor: 'transparent', borderRadius: "12px", padding: "4%" }}
            type="text"
            value={areaName}
            onChange={(e) => setAreaName(e.target.value)}
            placeholder="Enter Area Name..."
          />
        </Form.Group>

        <Form.Group className="mb-2">
          
          <Form.Label>Area Dimensions</Form.Label>
          <Stack direction='horizontal' gap="2">
          <InputGroup className="mb-2">
            <Form.Control
              style={{ background: "#393E46", color: "#F7F7F7", borderColor: 'transparent', borderLeftRadius: "12px", padding: "4%" }}
              type="text"
              value={length}
              onChange={(e) => setLength(e.target.value)}
              placeholder="Length..."
            />
            <InputGroup.Text>m</InputGroup.Text>
          </InputGroup>
          <InputGroup className="mb-2">
            <Form.Control
              style={{ background: "#393E46", color: "#F7F7F7", borderColor: 'transparent', borderLeftRadius: "12px", padding: "4%" }}
              type="text"
              value={breadth}
              onChange={(e) => setBreadth(e.target.value)}
              placeholder="Breadth..."
            />
            <InputGroup.Text>m</InputGroup.Text>
          </InputGroup>
          </Stack>
        </Form.Group>

        <Form.Group className="mb-2">
          <Form.Label>Area Description</Form.Label>
          <Form.Control
            style={{ background: "#393E46", color: "#F7F7F7", borderColor: 'transparent', borderRadius: "12px", padding: "4%" }}
            as="textarea"
            rows={7}
            value={areaDescription}
            onChange={(e) => setAreaDescription(e.target.value)}
            placeholder="Enter Area Description..."
          />
        </Form.Group>

        <Form.Group className="mb-2">
          <Form.Label>Details</Form.Label>
          <Form.Select aria-label="Details" size='sm' onChange={(e) => handleDetailChange(e.target.value)}>
            <option>Select Details</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </Form.Select>
        </Form.Group>

        <br />
        <br />

        <Button size="lg" variant="primary" onClick={() => handleSend()} style={{ width: "100%", borderRadius: "24px", padding: "2%" }}>
          Generate Space
        </Button>
      </Form>
    </Container>
  );
};

export default ChatAi;
