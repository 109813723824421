import React, { useState } from 'react';
import { Container, Row, Col,Navbar,Image } from 'react-bootstrap';
import Signup from '../components/Signup';
import Footer from '../components/Footer';

const Auth = ({handleUser}) => {

  const handleUserAuth =(data)=>{
    handleUser(data);
    
  }

  return (
    <Container fluid>
<iframe src = "https://share.streampixel.io/share-6032eba1-0fce-4ad7-9173-9817c0e78661" width="100vw" height="100vh" style={{
  width:"100vw",height:"100vh",position:"fixed"
}}/>
{/*
<>
     <Navbar bg="transparent" expand="lg">
      <Container fluid>
        <Navbar.Brand style={{
            marginLeft:"4%"
        }}>
          <h3 width="60%" style={{ fontFamily: "Comfortaa-Light",color:"#fff" }}>Genesis 3D</h3>
        </Navbar.Brand>
   
      </Container>
    </Navbar>

    <Row style={{marginTop:"15%"}}>
      <Col xs={5}></Col>
      <Col xs={2}>
    
      </Col>
      <Col xs={5}></Col>
      
        
    </Row>

    <Footer/>
</>
    */}
  </Container>
  );
};

export default Auth;
